<template>
  <div class="orderinfo">
     <div class="title">
        <img class="blocks" src="../../../../src/assets/image/black.png" alt="" @click="goblack">
        <span>服务单详情</span>
        <img @click="openService()" class="customer" src="../../../../src/assets/image/customer.png" alt="">
     </div>
     <div class="status">
        <div class="name">
          {{orderInfo.stateText}}
        </div>
        <van-steps :active="orderInfo.refund.state-1" active-color="#E0BFB1">
            <van-step>申请</van-step>
            <van-step>审核</van-step>
            <van-step>寄回</van-step>
            <van-step>收货</van-step>
            <van-step>退款</van-step>
            <van-step>完成</van-step>
        </van-steps>
     </div>
      <div class="site site2" @click="goSchedule()">
          <div class="site_text">
           <div class="text1">{{orderInfo.refund.desc1}}</div>
           <van-icon name="arrow" />
          </div>
          <div class="time" v-if="orderInfo.refund.desc2">{{orderInfo.refund.desc2}}</div>
      </div>

      <div class="site site2" v-if="orderInfo.refund.state == 2">
          <div class="site_text">
           <div class="text1">
            <img src="../../../../src/assets/image/logo.png" alt="" srcset="">
            {{orderInfo.refund.address.name}} {{orderInfo.refund.address.phone}}
           </div>
           <van-button color="#B89E94" size="mini" @click.stop="doCopy(orderInfo.refund.address.name + orderInfo.refund.address.phone + orderInfo.refund.address.details)" plain>复制</van-button>
          </div>
          <div class="time" style="margin-left: 28rem;margin-top: 0rem">{{orderInfo.refund.address.details}}</div>
      </div>
      
      <!-- 单号 -->
      <van-cell-group style="margin-top: 10rem" v-if="orderInfo.refund.state != 1">
        <van-cell v-if="orderInfo.refund.state == 2" title="寄回单号" is-link @click="show = true" :value="huiOrderId" />
        <van-cell v-if="orderInfo.refund.state == 2" title="快递公司" is-link @click="showPicker = true" :value="gsname" />
        <van-cell title="退款总额" :value="orderInfo.amount" />
        <van-cell title="退款方式" value="原支付返回" />
      </van-cell-group>

      <!-- 商品 -->
      <div class="goods" v-for="(item, index) in orderInfo.sub" :key="index">
         <img :src="item.topPic" alt="">
         <div class="goodinfo">
            <div class="name">{{item.name}}</div>
            <div class="tags"><span>{{item.skuAttr}}</span><span>×{{item.number}}</span></div>
            <div class="price">
              ￥{{item.skuPrice}}
            </div>
         </div>
      </div>
      <!-- 订单 -->
      <div class="order_nav">
         <div class="order_flex">
            <div class="left">服务单号</div>
            <div class="right">{{orderInfo.orderId}}</div>
         </div>
         <div class="order_flex">
            <div class="left">申请时间</div>
            <div class="right">{{orderInfo.createTime}}</div>
         </div>
         <div class="order_flex">
            <div class="left">服务类型</div>
            <div class="right">{{orderInfo.refundType == 1?'退货':'换货'}}</div>
         </div>
      </div>
    <bottomList></bottomList>
      <!-- last -->
      <div class="last_but">
          <span v-if="orderInfo.refund.state == 1 || orderInfo.refund.state == 2" @click.stop="cancelRefund()">取消申请</span>
          <span @click="editShow = true" v-if="orderInfo.refund.state == 3">修改单号</span>
          <span v-if="orderInfo.refund.state == 2" class="next_but" @click.stop="toNext()">下一步</span>
          <span class="next_but" v-if="orderInfo.refund.state == 5" @click.stop="doResolved()">已解决</span>
      </div>
      <van-popup v-model="show" class="pop_box">
        <div class="pop_title">请输入快递单号</div>
        <div class="pop_input">
            <input type="text" v-model="huiOrderIdNum" placeholder="用来监控寄回商品的位置">
        </div>
        <div class="pop_but">
            <span @click="show = false">取消</span>
            <span class="qued" @click="show = false; huiOrderId = huiOrderIdNum">确认</span>
        </div>
      </van-popup>
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            value-key="name"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
        </van-popup>
        <van-popup v-model="editShow" class="pop_box">
            <div class="pop_title">修改快递单号</div>
            <div class="pop_input">
                <input v-model="huiOrderIdNum" type="text" placeholder="请输入快递单号">
            </div>
            <div class="pop_but">
                <span  @click="editShow = false">取消</span>
                <span @click="toNext1()" class="qued">确认</span>
            </div>
        </van-popup>
  </div>
</template>

<script>
import { getOrderInfo } from '@/api/order'
import { getCompany, cancelRefund, submitRefund } from '@/api/service' // 退换公司 取消申请 提交快递单号

export default {
  name: "serviceOrderDetail",

  data() {
    return {
      active: 1,
      orderInfo: {},
      NowTime: '', // 当前时间
      show: false,
      columns: [],
      showPicker: false,
      editShow: false,
      gsname: '请选择寄回快递公司',
      huiOrderId: '请输入寄回商品的快递单号',
      huiOrderIdNum: '',
      expressCompanyId: '',
    };
  },

  mounted() {
    if(this.$route.params.orderId) {
        localStorage.setItem('orderId', this.$route.params.orderId)
    } else {
        this.$route.params.orderId = localStorage.getItem('orderId')
    }
    // this.$route.params.orderId = 207269751279632
    this.getInfo()
    // 获取快递公司
    this.getCompany()
  },

  methods: {
    // 跳转客服
    openService() {
        window.open('https://chatbot.aliyuncs.com/intl/index.htm?locale=zh-CN&from=blD4dOkH24')
    },
    // 查看物流进度
    goSchedule() {
        var that = this
        if (that.orderInfo.refund.state == 3) {
             that.$router.push({
                name: `schedule`,
                params: {
                    orderId: that.orderInfo.orderId
                }
            })
        }
       
    },
    // 获取快递公司
    getCompany() {
        getCompany().then(res => {
            this.columns = res.data
        })
    },
    // 返回上一页
    goblack() {
        // 返回上一页
       this.$router.go(-1);
    },
    onConfirm(value) {
    //   this.value = value;
      this.gsname = value.name
      this.expressCompanyId = value.id
      this.showPicker = false;
    },
    // 获取订单信息
    getInfo() {
      var that = this
      getOrderInfo(that.$route.params.orderId).then(res => {
        that.orderInfo = res.data
        if (that.orderInfo.refund.expressNo) {
            that.huiOrderId = that.orderInfo.refund.expressNo
            that.huiOrderIdNum = that.orderInfo.refund.expressNo
            that.gsname = that.orderInfo.refund.expressCompanyName
            that.expressCompanyId = that.orderInfo.refund.expressCompanyId
        }
      })
    },
    // 去复制
    doCopy(con) {
      try {
        const textToCopy = con
        navigator.clipboard.writeText(textToCopy)
        this.$layer.quicktips('复制成功')
      } catch (err) {
        this.$layer.quicktips('复制失败')
      }
    },
    // 取消申请
    cancelRefund() {
        var that = this
        cancelRefund(that.orderInfo.orderId).then(res => {
            if (res.state == 200) {
                that.$layer.quicktips('取消申请成功')
                // 跳转服务详情
                that.$router.push({
                    name: `serviceCancel`,
                    params: {
                        orderId: that.$route.params.orderId
                    }
                })
            }
        })
    },
    // 下一步
    toNext() {
        var that = this
        var data = {
            orderId: that.orderInfo.orderId,
            expressNo: that.huiOrderIdNum,
            expressCompanyId: that.expressCompanyId
        }
        if (!data.expressNo) {
           return that.$layer.quicktips('请填写快递单号')
        }
        if (!data.expressCompanyId) {
            return that.$layer.quicktips('请选择快递公司')
        }
        submitRefund(data).then(res => {
            if (res.state == 200) {
                that.orderInfo.refund.state = 3
            }
        })
    },
    // 修改快递单号
    toNext1() {
        var that = this
        var data = {
            orderId: that.orderInfo.orderId,
            expressNo: that.huiOrderIdNum,
            expressCompanyId: that.expressCompanyId
        }
        if (!data.expressNo) {
           return that.$layer.quicktips('请填写快递单号')
        }
        if (!data.expressCompanyId) {
            return that.$layer.quicktips('请选择快递公司')
        }
        submitRefund(data).then(res => {
            if (res.state == 200) {
                that.orderInfo.refund.state = 3
                that.editShow = false
            }
        })
    },
    // 已解决
    doResolved() {
        var that = this
        cancelRefund(that.orderInfo.orderId).then(res => {
            if (res.state == 200) {
                that.$layer.quicktips('已解决')
                that.orderInfo.refund.state = 6
            }
        })
    },
  },
};
</script>

<style lang="less" scoped>
.orderinfo{
   background: #F6F7FA;
   padding-bottom: 40rem;
   width: 100vw;
   height: 100vh;
}
.title{
    position: relative;
    text-align: center;
    padding: 14rem 0rem;
    background: #fff;
    .blocks{
        position: absolute;
        left: 14rem;
        width: 17rem;
        height: 17rem;
    }
    span{
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 17rem;
        color: #333333;
    }
    .customer{
        position: absolute;
        right: 14rem;
        width: 23rem;
        height: 21rem;
    }
}
.status{
  width: calc(100vw - 32rem);
  height: 102rem;
  padding: 0rem 16rem;
  background: #000000;
  background: url('../../../../src/assets/image/bj.png');
  background-size: 100% 100%;
  .name{
    font-family: AlibabaPuHuiTi, AlibabaPuHuiTi;
    font-weight: 800;
    font-size: 17rem;
    color: #FFFFFF;
    padding-top: 22rem;
  }
  .tig{
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 13rem;
    color: #F6F7FA;
    margin-top: 2rem;
  }
}

.site{
    width: calc(100vw - 30rem);
    // height: 44rem;
    background: #FFFFFF;
    padding: 15rem;
    .site_text{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text1{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 15rem;
            color: #333333;
            img{
                width: 25rem;
                height: 26rem;
                vertical-align: middle;
            }
        }
    }
    .time{
        font-family: PingFangSC, PingFang SC;
        font-weight: 300;
        font-size: 14rem;
        color: #000000;
        margin-top: 4rem;
    }
}
.site2{
  margin-top: 10rem;
  .imgdinwei{
    width: 23rem !important;
  }
}
.goods{
  margin-top: 10rem;
  display: flex;
  padding: 15rem;
  background: #fff;
  img{
    width: 98rem;
    height: 98rem;
  }
  .goodinfo{
    margin-left: 8rem;
    width: calc(100% - 116rem);
    .name{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #000000;
    }
    .tags{
      display: flex;
      margin-top: 5rem;
      justify-content: space-between;
      span{
          font-family: PingFangSC, PingFang SC;
          font-weight: 300;
          font-size: 13rem;
          color: #939297;
      }
    }
    .price{
      font-family: DINPro, DINPro;
      font-weight: 500;
      font-size: 14rem;
      color: #000000;
      margin-top: 32rem;
    }
  }
}
.order_nav{
  margin-top: 10rem;
  padding: 18rem 14rem;
  background: #fff;
  .order_flex{
    display: flex;
    justify-content: space-between;
    padding-bottom: 18rem;
    .left{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #666C73;
    }
    .right{
      font-family: PingFangSC, PingFang SC;
      font-weight: 300;
      font-size: 14rem;
      color: #000000;
    }
  }
  .order_all{
    padding-top: 14rem;
    text-align: right;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14rem;
    color: #000000;
    border-top: 1rem solid #F6F7FA;
  }
}
.last_but{
  width: 100vw;
  height: 60rem;
  line-height: 60rem;
  background: #FFFFFF;
  border: 1rem solid #F0F0F0;
  position: fixed;
  bottom: 0rem;
  left: 0rem;
  text-align: right;
  span{
    display: inline-block;
    line-height: 44rem;
    width: 107rem;
    height: 44rem;
    text-align: center;
    border: 1rem solid #C5C8D3;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 15rem;
    color: #000000;
    margin-right: 9rem;
    margin-top: 7rem;
  }
  .qrsh{
    background: #E2BFB0;
    border: none !important;
  }
}
/deep/.van-steps,/deep/ .van-step--horizontal .van-step__circle-container{
    background-color: transparent;
}
.next_but{
    background: #E0BFB1;
    border: none;
}
.pop_box{
    width: 247rem;
    height: 150rem;
    background: #FFFFFF;
    padding: 24rem;
    .pop_title{
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 18rem;
        color: #3C3C3C;
        text-align: center;
    }
    .pop_input{
        margin-top: 12rem;
        input{
            height: 46rem;
            background: #F6F7FA;
            border: none;
            width: calc(100% - 10rem);
            padding-left: 10rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15rem;
            color: #C5C8D3;
        }
    }
    .pop_but{
        margin-top: 27rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
            display: inline-block;
            width: 116rem;
            height: 40rem;
            border: 1rem solid #ACB5B9;
            text-align: center;
            line-height: 40rem;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            font-size: 15rem;
            color: #000000;
        }
        .qued{
            border: none;
            background: #E0BFB1;
        }
    }
}
</style>
